<template>
    <div class="lookup">
        <b-modal id="modal-prevent-closing" ref="modal" :title=title @show="resetModal" @hidden="resetModal" @ok="handleOk" size="lg">
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-button v-if="resultTable == 'patient' || resultTable == 'accommodation' || resultTable == 'professionalAdmission'" class="mb-3" 
                    variant="success" @click="newCadastre">NOVO</b-button>                
                <b-row>
                    <b-col md="8" sm="12">
                        <b-form-input size="sm" placeholder="Filtrar..." v-model="ftext"></b-form-input>
                    </b-col>
                    <b-col md="2" sm="12">
                        <b-button size="sm" @click="loadProfessionals" v-if="resultTable == 'professionals' || resultTable == 'professionalScale' ||
                                                                   resultTable == 'professionalsUpdate' || resultTable == 'professionalInsert'">
                            <i class="fa fa-search-plus"></i>
                        </b-button>

                        <b-button size="sm" @click="loadPatients" v-if="resultTable == 'patients' || 
                                                              resultTable == 'patient' || 
                                                              resultTable == 'patientScheduling'">
                            <i class="fa fa-search-plus"></i>
                        </b-button>                      
                    </b-col>
                </b-row>
                <hr>
                <b-form-group :invalid-feedback=invalidFeedback :state="stateResult">
                    
                    <b-table head-variant="light" outlined hover :fields=fields responsive="sm" id="my-table" :items=list
                            :per-page="perPage" :current-page="currentPage" fixed :filter="ftext" selectable :select-mode=selectMode
                            @row-selected="onRowSelected">

                            <template v-if="resultTable == 'qualifications'" v-slot:cell(description)>
                                <b-form-textarea id="textarea" placeholder="Descrição..." rows="1" max-rows="6" @input="setDescription"></b-form-textarea>       
                            </template>

                            <template v-if="resultTable == 'professionals' || resultTable == 'scaleBase' || resultTable == 'professionalInsert'" v-slot:cell(checkin)>
                                <b-form-timepicker @input="setCheckin" placeholder="" hide-header required :state="stateCheckin"></b-form-timepicker>      
                            </template>   

                            <template v-if="resultTable == 'professionals' || resultTable == 'scaleBase' || resultTable == 'professionalInsert'" v-slot:cell(checkout)>
                                <b-form-timepicker @input="setCheckout" placeholder="" hide-header required :state="stateCheckout"></b-form-timepicker>      
                            </template>                                                 

                            <template v-if="resultTable == 'priceTypeService'" v-slot:cell(valueTA)>
                                <b-input type="number" required @input="setValueTA"></b-input>
                            </template>  
                            
                            <template v-if="resultTable == 'priceTypeService'" v-slot:cell(codeTA)>
                                <b-input type="number" required @input="setCodeTA"></b-input>
                            </template>  

                            <template v-if="resultTable == 'priceTypeService'" v-slot:cell(tableTypeTA)>
                                <b-input type="number" required @input="setTableTypeTA"></b-input>                                
                            </template>  

                            <template v-if="resultTable == 'priceTypeService'" v-slot:cell(descriptionTA)>
                                <b-input type="text" required @input="setDescriptionTA"></b-input>
                            </template>  
                    </b-table>        

                    <div v-if="rows == 0 && loading == true" class="d-flex justify-content-center mb-3">    
                        <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" type="grow"></b-spinner>
                    </div>

                    <b-pagination size="sm" pills v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table" class="page-item">
                    </b-pagination>
                </b-form-group>                                       
            </form>
        </b-modal>                                          
    </div>
</template>

<script>
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'

export default {    
    name: 'Lookup',
    props: [
        'title', 
        'invalidFeedback',
        'list',
        'fields',
        'resultTable',    
        'type'
    ],
    data: function() {
        return {
            ftext: '',
            currentPage: 1,
            perPage: 10,            
            selectMode: 'single',
            result: null,
            stateResult: null,
            description: '',
            checkin: null,
            stateCheckin: null,
            checkout: null,
            stateCheckout: null,
            repeatfor: null,
            loading: false,
            repeatformonths: [
                "",
                "1 Mês",
                "2 Mêses",
                "3 Mêses",
                "4 Mêses",
                "5 Mêses",
                "6 Mêses",
                "7 Mêses",
                "8 Mêses",
                "9 Mêses",
                "10 Mêses",
                "11 Mêses",
                "1 Ano"
            ],
            codeTA: null,
            valueTA: null,
            tableTypeTA: null,
            descriptionTA: null
        }
    },
    computed: {
      rows() {
          if (this.list == null) {
            return 0
          } else {
            return this.list.length
          }
      }
    },    
    methods: {
        loadProfessionals() { 
            this.loading = true

            const url = `${baseApiUrl}/professional/perName/${this.ftext.toUpperCase() ? this.ftext.toUpperCase() : null}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                this.list = res.data            
            }).catch(showError)
            
            this.loading = false
        },
        loadPatients() {
            this.loading = true

            const url = `${baseApiUrl}/patient/perNameOrCPF/${this.ftext.toUpperCase() ? this.ftext.toUpperCase() : null}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                this.list = []
                
                for (let r of res.data) {     
                    let dataBirthDay = r.birthday.split('-')

                    var dd = dataBirthDay[2]
                    var mm = dataBirthDay[1]
                    var yyyy = dataBirthDay[0]

                    if (dd < 10 && dd.length < 2) {
                        dd = '0' + dd
                    } 

                    if (mm < 10 && mm.length < 2) {
                        mm = '0' + mm
                    }                            
                                        
                    this.list.push({
                        _id: r._id,
                        name: r.name,
                        cpf: r.cpf,
                        rg: r.rg,
                        email: r.email,
                        phone1: r.phone1,
                        phone2: r.phone2,
                        birthday: `${dd}/${mm}/${yyyy}`,
                        covenant: r.covenant,
                        questions: r.questions
                    })
                }
            }).catch(showError)
            
            this.loading = false
        },
        newCadastre() {
            if (this.resultTable == 'patient') {
                this.$router.push({name: 'patient', params: {}})
            } else if (this.resultTable == 'accommodation') {
                this.$router.push({name: 'accommodation', params: {}})            
            } else if (this.resultTable == 'professionalAdmission') {
                this.$router.push({name: 'professional', params: {}})
            }            
        },
        setDescription(text) {
            this.description = text 
        },
        setCodeTA(text) {
            this.codeTA = text 
        },        
        setTableTypeTA(text) {
            this.tableTypeTA = text 
        },        
        setDescriptionTA(text) {
            this.descriptionTA = text 
        },        
        setValueTA(text) {
            this.valueTA = text 
        },        
        setCheckin(value) {
            this.checkin = value
        },
        setCheckout(value) {
            this.checkout = value
        },      
        setRepeatFor(value) {
            this.repeatfor = value
        },                
        onRowSelected(itemSelected) {          
            if (itemSelected.length > 0) {
                if (this.resultTable === 'qualifications') {
                    this.result = {
                        level: itemSelected[0].level,
                        description: ''
                    }
                } else if (this.resultTable === 'professionals' || this.resultTable === 'scaleBase') {
                    this.result = {
                        name: itemSelected[0].name,
                        cpf: itemSelected[0].cpf,
                        checkin: null,
                        checkout: null,
                        repeatfor: null,
                    }
                } else if (this.resultTable === 'typeService') {
                    if (!this.result) {
                        this.result = []
                    }

                    this.result = itemSelected
                } else if (this.resultTable === 'priceTypeService') {
                    this.result = {
                        name: itemSelected[0].name,
                        codeTA: null,
                        tableTypeTA: null,
                        descriptionTA: null,
                        valueTA: null
                    }
                } else {
                    this.result = itemSelected[0]           
                }
            }
        },
        resetModal() {
            this.result = null
            this.stateResult = null
            this.checkin = null
            this.checkout = null
            this.stateCheckin = null
            this.stateCheckout = null
            this.codeTA = null,
            this.tableTypeTA = null,
            this.descriptionTA = null,
            this.valueTA = null            
        },           
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        }, 
        checkFormValidity() {
            if (this.result == null) {
                this.stateResult = false
                return false
            } else {
                this.stateResult = true

                if (this.resultTable === 'professionals' || this.resultTable == 'scaleBase') {
                    if (this.checkin != null && this.checkout != null) {                
                        this.stateCheckin = true
                        this.stateCheckout = true
                        return true
                    } else {
                        if (this.checkin == null)
                            this.stateCheckin = false

                        if (this.checkout == null) 
                            this.stateCheckout = false

                        return false
                    }
                } else if (this.resultTable === 'priceTypeService') {
                    if (this.codeTA == null) {
                        return false                        
                    } else {
                        if (this.valueTA == null) { 
                            return false
                        } else {
                           if (this.tableTypeTA == null) {
                               return false
                           } else {
                               if (this.descriptionTA == null) {
                                   return false
                               } else {
                                   return true
                               }
                           }
                        }
                    }
                } else {
                    return true
                }                
            }
        },     
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return 
            }

            if (this.resultTable === 'patient') {
                this.$store.commit("setResultLookupPatient", this.result)    
            } else if (this.resultTable === 'patients') {
                this.$store.commit("setResultLookupPatientArray", this.result)    
            } else if (this.resultTable === 'accommodation') {        
                this.$store.commit("setResultLookupAccommodation", this.result)    
                
                if (this.type == 'Leito') {
                    this.$store.commit("setResultLookupContainedAccommodation", `${this.result.name} -> ${this.result.floor} -> ${this.result.building}`)    
                } else if (this.type == 'Apartamento') {                
                    this.$store.commit("setResultLookupContainedAccommodation", `${this.result.name} -> ${this.result.building}`)    
                } else if (this.type == 'Andar') {
                    this.$store.commit("setResultLookupContainedAccommodation", this.result.name)    
                }
            } else if (this.resultTable === 'typeService') {
                for (let r of this.result) {
                    this.$store.commit("setResultLookupTypeService", r)
                }
            } else if (this.resultTable === 'speciality') {
                this.$store.commit("setResultLookupSpeciality", this.result)
            } else if (this.resultTable === 'qualifications') {
                this.result.description = this.description
                this.$store.commit("setResultLookupQualification", this.result)
            } else if (this.resultTable === 'professionals' || this.resultTable == 'scaleBase') {
                this.result.checkin = this.checkin
                this.result.checkout = this.checkout
                this.result.repeatfor = this.repeatfor
                this.$store.commit("setResultLookupProfessional", this.result)
            } else if (this.resultTable === 'professionalScale') {
                this.$emit('event', { professionalSelected: this.result})
            } else if (this.resultTable === 'professionalAdmission') {
                this.$emit('event', { professionalSelected: this.result})    
            } else if (this.resultTable === 'professionalInsert') {
                this.result.checkin = this.checkin
                this.result.checkout = this.checkout                
                this.$emit('event', { professionalSelected: this.result})                            
            } else if (this.resultTable === 'professionalsUpdate') {
                this.$emit('event', { professionalSelected: this.result})
            } else if (this.resultTable === 'priceTypeService') {
                this.result.codeTA = this.codeTA
                this.result.tableTypeTA = this.tableTypeTA
                this.result.descriptionTA = this.descriptionTA
                this.result.valueTA = this.valueTA
                this.$emit('event', { typeServiceSelected: this.result})
            } else if (this.resultTable === 'patientScheduling') {                
                this.$emit('event', { patientSelected: this.result})
            } else if (this.resultTable === 'patientScale') {                
                this.$emit('event', { patientSelected: this.result})    
            } else if (this.resultTable === 'questions') {                
                this.$emit('event', { questionSelected: this.result})      
            } else if (this.resultTable === 'typeServiceSecondary') {                
                this.$emit('event', { typeServiceSelected: this.result}) 
            } else if (this.resultTable === 'questionnaires') {                
                this.$emit('event', { questionnaireSelected: this.result})    
            } else if (this.resultTable === 'relAttendancesPerCovenant' || this.resultTable === 'relAdmissionsPerCovenant') {                
                this.$emit('event', { covenantSelected: this.result})                    
            } else {
                this.$store.commit("setResultLookup", this.result)
            } 
            
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },
    },
    mounted() {
        if (this.resultTable == 'typeService') {
            this.selectMode = 'multi'
        }
    }
}
</script>

<style>

</style>