















<template>
    <div :class="`maintenance-content-${theme}`">
      <div :class="`maintenance-modal-${theme}`">
        <hr />
        <div :class="`maintenance-title-${theme}`"><b>SISTEMA EM MANUTENÇÃO</b></div>
        <br>
        <!-- <i :class="`img-${theme} fa fa-wrench`" style='font-size:200px;'></i> -->
        <hr />
        <br>
        <br>
        <b-badge variant="light">Versão 1.0.328</b-badge>
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
    name: "Maintenance",
    data: function () {
      return {
        theme: null,
        logo: '',
        mainProps: { blank: true, blankColor: '#7777', width: 200, height: 50, class: 'm1' }
      }
    },
    mounted() {
      this.theme = localStorage.getItem('theme')    
    },
  };
  </script>
  
  <style>
  .img-green {
    color: green;
    height: 200px;
  }
  
  .img-wine {
    color: #7A1120;
    height: 200px;
  }
  
  .img-violet {
    color: #6E4DA4;
    height: 200px;
  }
  
  .maintenance-content-green {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7fff9;
  }
  
  .maintenance-content-wine {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 241, 241);
  }
  
  .maintenance-content-violet {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f3eaff;
  }
  
  .maintenance-modal-green {
    border: 2px solid rgb(13, 99, 66);
    background-color: rgb(221, 241, 234);
    width: 500px;
    padding: 35px;
    box-shadow: 0 25px 30px #0d413f;
  
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .maintenance-modal-wine {
    border: 2px solid rgb(99, 13, 13);
    background-color: rgb(255, 241, 241);
    width: 500px;
    padding: 35px;
    box-shadow: 0 25px 30px rgb(99, 13, 13);
  
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .maintenance-modal-violet {
    border: 2px solid #6E4DA4;
    background-color: #f3eaff;
    width: 500px;
    padding: 35px;
    box-shadow: 0 25px 30px #6E4DA4;
  
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .maintenance-title-green {
    font-size: 20pt;
    font-weight: 100;
    margin-top: 10px;
    margin-bottom: 15px;
    color: #0d413f;
  }
  
  .maintenance-title-wine {
    font-size: 20pt;
    font-weight: 100;
    margin-top: 10px;
    margin-bottom: 15px;
    color: #7A1120;
  }
  
  .maintenance-title-violet {
    font-size: 20pt;
    font-weight: 100;
    margin-top: 10px;
    margin-bottom: 15px;
    color: #6E4DA4;
  }
  
  .maintenance-modal-green hr {
    border: 0;
    width: 100%;
    height: 1px;
    background-image: linear-gradient(to right,
        rgba(120, 120, 120, 0),
        rgba(120, 120, 120, 0.75),
        rgba(120, 120, 120, 0));
  }
  
  .maintenance-modal-wine hr {
    border: 0;
    width: 100%;
    height: 1px;
    background-image: linear-gradient(to right,
        rgba(120, 120, 120, 0),
        rgba(120, 120, 120, 0.75),
        rgba(120, 120, 120, 0));
  }
  
  .maintenance-modal-violet hr {
    border: 0;
    width: 100%;
    height: 1px;
    background-image: linear-gradient(to right,
        rgba(120, 120, 120, 0),
        rgba(120, 120, 120, 0.75),
        rgba(120, 120, 120, 0));
  }
  </style>
  

















































































































































