<template>
  <div class="batchGuidesANSEdit">
      <PageTitle icon="fa fa-hashtag" main="ANS" sub="Edição de Guia" />

      <div v-for="item in guideANS.guides" v-bind:key="item.id">
         <b-card :class="`card-${theme} mt-3`" :header="item.numeroGuiaPrestador.toString()" border-variant="dark" header-border-variant="white" header-bg-variant="dark" header-text-variant="white">
            <b-row>
                <b-col md="3" sm="12">  
                    <b-form-group label="Nome Beneficiário:" label-for="guide-name">
                        <b-form-input size="sm" id="guide-name" type="text" v-model="item.nomeBeneficiario" disabled/>
                    </b-form-group>    
                </b-col>
                <b-col md="3" sm="12">  
                    <b-form-group label="Solicitante:" label-for="guide-professionalRequester">
                        <b-form-input size="sm" id="guide-professionalRequester" type="text" v-model="item.nomeProfissional" required disabled/>
                    </b-form-group>                                           
                </b-col>
                <b-col md="2" sm="12">  
                    <b-form-group label="CRM Solicitante:" label-for="guide-professionalRequesterCRM">
                        <b-form-input size="sm" id="guide-professionalRequesterCRM" type="text" v-model="item.numeroConselhoProfissional" required disabled/>
                    </b-form-group>                                           
                </b-col>                
                <b-col md="1" sm="6" class="align-self-center mt-3">
                    <b-button size="sm" @click="setModal(item.numeroGuiaPrestador.toString())" v-b-modal.modal-prevent-closing v-b-popover.hover.top="'Adiconar Profissional'">
                        <i class="fa fa-search"></i>
                    </b-button>

                    <Lookup v-if="idModal == item.numeroGuiaPrestador.toString()" :id="item.numeroGuiaPrestador.toString()" ref="Lookup" title="Profissionais" 
                        :list="resultProfessionals" :fields="fieldsPr" :invalidFeedback="invalidFeedback"  
                        @event="addProfessional" resultTable="professionalInsert"></Lookup>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="2" sm="12">  
                    <b-form-group label="Número Carteira:" label-for="guide-numberCard">
                        <b-form-input size="sm" id="guide-numberCard" type="text" v-model="item.numeroCarteira" required/>
                    </b-form-group>                                            
                </b-col>
                <b-col md="2" sm="12">  
                    <b-form-group label="Validade Carteira:" label-for="guide-validCard">
                        <b-form-input size="sm" id="guide-validCard" type="date" v-model="item.validadeCarteira" required/>
                    </b-form-group>                                            
                </b-col>                
                <b-col md="2" sm="12">  
                    <b-form-group label="Senha:" label-for="guide-password">
                        <b-form-input size="sm" id="guide-password" type="text" v-model="item.senha" required/>
                    </b-form-group>                                            
                </b-col>
                <b-col md="2" sm="12">  
                    <b-form-group label="Número Guia Operadora:" label-for="guide-numberGuia">
                        <b-form-input size="sm" id="guide-numberGuia" type="text" v-model="item.numeroGuiaOperadora" required/>
                    </b-form-group>                                           
                </b-col>
            </b-row>
         </b-card> 
      </div>      

    <b-button size="sm" variant="success" @click="save" class="mt-3">SALVAR</b-button>  
    <b-button size="sm" variant="danger" @click="cancel" class="mt-3 ml-2">CANCELAR</b-button>       
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'
import Lookup from "../default/Lookup"

export default {
    name: "batchGuidesANSEdit",
    components: { PageTitle, Lookup }, 
    data: function() {
        return {
            theme: null,
            guide: null,
            guideANS: null,
            invalidFeedback: '',
            idModal: 0,
            resultProfessionals: [],
            fieldsPr: [
                { key: "name", label: "Nome", sortable: true },
                { key: "cpf", label: "CPF", sortable: true },
                { key: "advice.number", label: "Registro", sortable: true },
            ]
        }   
    },
    methods: {
        cancel() {
            this.$router.go(-1)
        },
        save() {
            this.$bvModal.msgBoxConfirm('Deseja atualizar as informações do lote de guias?', {
                title: 'Confirmação',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Sim',
                cancelTitle: 'Não',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            }).then(value => {
                if (value) {
                    let data = {
                        idGuide: this.guide._id,
                        covenant: this.guide.covenant._id,
                        guides: this.guideANS.guides,
                        allGuides: this.guideANS.allGuides,
                        header: this.guideANS.header,
                        listDates: this.guide.listDates,
                        revert: this.guide.revert
                    }

                    const url = `${baseApiUrl}/guideANS/regenerateXmlANS/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
                    axios.post(url, data).then(() => this.$router.go(-1)).catch((showError))
                }
            }).catch(showError)
        },
        addProfessional(payload) {
            this.guideANS.guides.map(el => {
                if (el.numeroGuiaPrestador == this.idModal) {
                    el.nomeProfissional = payload.professionalSelected.name
                    el.numeroConselhoProfissional = payload.professionalSelected.advice.number
                }
            })
        },
        setModal(id) {
            this.idModal = id
        }      
    },
    created() {
        this.theme = localStorage.getItem('theme')

        if (this.$route.params.guideSelected) {
            this.guide = this.$route.params.guideSelected

            this.guideANS = JSON.parse(this.guide.xmlToJson)
        }
    } 
}
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}
</style>